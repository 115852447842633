import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Alert, Grid, Stack, Snackbar, Typography, Box, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import PersonIcon from "@mui/icons-material/PersonTwoTone";
import EditNoteIcon from "@mui/icons-material/EditNote";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import EmailIcon from "@mui/icons-material/Email";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined";
import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import _ from "lodash";

import { io } from "socket.io-client";
import { useSnackbar } from "notistack";
import ActivityLogSidebar from "./ActivityLogSidebar";
import ServiceLogSidebar from "./ServiceLogSidebar";
import Comments from "./Comments";
// import Notes from "./Notes";
import EditorNotes from "./EditorNotes";
import { reusableFetch } from "../../utils/useAxios";
import {
  lenderApi,
  useGetCommercialLendersQuery,
  useGetConsumerLendersQuery,
  useGetPersonalLendersQuery,
} from "../../store/slices/apiSlice";
import Asset from "../Asset/index";
import LoanDetails from "../LoanDetails/index";
import Supplier from "../Supplier/index.js";
import PageLoader from "../Application/PageLoader.js";
import convertToOrdinal from "../../utils/convertToOrdinal";
import { fieldRequiredMessage, APPLICATION_TYPES, fieldEntityRequiredMessage, STATUS_CODE, ERRORS_MESSAGE, SUCCESS_MESSAGE } from "../../constants";
import CustomerDetails from "../CustomerDetails";
import Sidebar from "../HOC/Sidebar";
import AddedEntity from "../Entity/AddedEntity";
import IntroducerDeclaration from "../IntroducerDeclaration";
import NCCPDeclaration from "../NCCPDeclaration";
import ManualQuote from "../ManualQuote";
import Entity from "../Entity/index.js";
import ProductSelector from "../ProductSelector/index.js";
import {
  saveValue,
  getApplication,
  applicationSelector,
  userDetailsSelector,
  clearState,
  createCustomer,
  getApplicationActivityLog,
  activityLogSelector,
  getApplicationServiceLog,
  // setRequiredFieldsError,
  setRequiredFieldsErrors,
  setValidationRequireFieldError,
  getApplicationComments,
  applicationCommentsSelector,
  getCloneApplication,
  setNewCommercialCreditFile,
  setNewCustomerCreditFile,
  setNewCustomerBankStatementFile,
  setNewDocumentFile,
  setNewAssetPPSRFile,
  setNewActivityLog,
  setNewServiceUsageLog,
  getApplicationDownloadUrl,
  submitApplicationToEmail,
  setCustomerEmploymentVerificationFile,
} from "../../store/slices/applicationFormSlice";
import regex from "../../utils/regex";
import { userPermissionsSelector } from "../../store/slices/userSlice";
import LoanSummary from "../LoanSummary";

function openInNewTab(url) {
  var win = window?.open(url, "_blank");
  win?.focus();
}

const Application = React.memo(() => {
  const dispatch = useDispatch();
  const { applicationId } = useParams();
  const navigate = useNavigate();  
  const { enqueueSnackbar } = useSnackbar();
  const permissions = useSelector(userPermissionsSelector);
  const isDisable = permissions?.application?.edit;

  function hasTrueValue(obj) {
    return Object.values(obj).includes(true);
  }

  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [openActivity, setOpenActivity] = useState(false);
  const [openServiceLog, setOpenServiceLog] = useState(false);
  const [openEmail, setOpenEmail] = useState(false);
  const [openSMS, setOpenSMS] = useState(false);
  const [openNotes, setOpenNotes] = useState(false);
  const [openComments, setOpenComments] = useState(false);
  const [isApplicationComplete, setIsApplicationComplete] = useState(false);
  const [submitButtonAction, setSubmitButtonAction] = useState({ color: "primary", text: "Submit" });
  // const [openManualQuote, setOpenManualQuote] = useState(false);

  const {
    linkSent,
    gotASICData,
    applicationServiceLog,
    applicationServiceLogLoader,
    openManualQuote,
    // lenderRequiredFieldsError,
    requireFieldErrors,
    validationRequireFieldError,
  } = useSelector(userDetailsSelector);
  // const { assetsFieldErrors, consumerFieldErrors, agreementsFieldErrors, nccpFieldErrors, entityFieldErrors } = lenderRequiredFieldsError
  // const { assetsFieldErrors, consumerFieldErrors } = lenderRequiredFieldsError
  const application = useSelector(applicationSelector);
  const comments = useSelector(applicationCommentsSelector);

  let { url, open, gettingApplication, gettingApplicationComment, gettingClonedApplication,loadingSubmitApplication } = useSelector(userDetailsSelector);

  const activityLog = useSelector(activityLogSelector);

  // const lenderChunks = useSelector(lenderChunksSelector);

  const [assetFieldErrors, setAssetFieldErrors] = useState({
    assetValue: "",
    typeOfSale: "",
    condition: "",
    make: "",
    model: "",
    glassesMake: "",
    glassesModel: "",
    glassesVariant: "",
    glassesNVIC: "",
    glassesData: "",
  });

  const [guarantorFieldErrors, setGuarantorFieldErrors] = useState({
    customerTitle: "",
    firstName: "",
    lastName: "",
    IDNumber: "",
    dateOfBirth: "",
    IDCardNumber: "",
    phoneNumber: "",
    emailAddress: "",
    address: "",
    yearsAt: "",
    monthsAt: "",
    IDExpiryDate: "",
  });

  const [guarantorsError, setGuarantorsError] = useState([]);

  const [agreementsFieldErrors, setAgreementsFieldErrors] = useState({
    introducerDeclarationAgree: "",
  });

  const [nccpFieldErrors, setNccpFieldErrors] = useState({
    whatIsThePurposeOfThisApplication: "", // Enum
    whyDoesThisLoanTermSuitYourRequirements: "", // Enum
    whatAreYouLookingForInYourLoan: "", // Enum
    anyForeseeableChangesImpactingLoanRepayment: "", // Enum
    howApplicantIntendToDoLoanRepaymentAfterChange: "", // Textarea
    increaseInLoanAmountForFinancingCostOfInsurance: "", // boolean
    balloonPaymentAtTheEndOfTheLoan: "", // boolean
    whatToDoWithBalloonPaymentAtTheEndOfTheLoanTerm: "", // Enum
  });

  const [entityFieldErrors, setEntityFieldErrors] = useState({
    address: "",
  });

  const [socket, setSocket] = useState(null);
  const [currentRoom, setCurrentRoom] = useState(null);

  useEffect(() => {
    if (!socket) {
      setSocket(
        io(`${process.env.REACT_APP_API_URL}/application`, {
          transports: ["websocket", "polling"],
          reconnection: true,
          reconnectionAttempts: Infinity,
          reconnectionDelay: 1000,
          reconnectionDelayMax: 5000,
          timeout: 10000,
          auth: {
            "x-access-token": localStorage.getItem("token") || "",
          },
        }),
      );
    }

    return () => {
      socket?.disconnect();
    };
  }, [socket]);

  useEffect(() => {
    if (!socket) return;
    if (applicationId) {
      if (currentRoom && currentRoom !== applicationId) {
        socket.emit("leaveApplicationRoom", currentRoom, () => {
          socket.emit("joinApplicationRoom", applicationId);
          setCurrentRoom(applicationId);
        });
      } else if (!currentRoom) {
        socket.emit("joinApplicationRoom", applicationId);
        setCurrentRoom(applicationId);
      }

      const handleNewCommercialCreditFile = (commercialCreditFile) => {
        dispatch(setNewCommercialCreditFile(commercialCreditFile));
      };

      const handleNewCustomerCreditFile = (customerCreditFile) => {
        dispatch(setNewCustomerCreditFile(customerCreditFile));
      };
      const handleChatOptionEnable = (chatOptions) => {
        dispatch(saveValue({
          isIntercomChatOptions: chatOptions
        }));
      };

      const handleNewCustomerEmploymentVerificationFile = (customerEmploymentVerificationFile) => {
        dispatch(setCustomerEmploymentVerificationFile(customerEmploymentVerificationFile));
      };

      const handleNewBankStatementFile = (bankStatementFile) => {
        dispatch(setNewCustomerBankStatementFile(bankStatementFile));
      };

      const handleNewDocumentFile = (customerPrivacyFile) => {
        // Generic handler for uploads that uses document file upload callback endpoint
        // e.g Connective privacy policy file uploads, identity verification file uploads
        dispatch(setNewDocumentFile(customerPrivacyFile));
      };

      const handleNewAssetPPSRFile = (assetPPSRFile) => {
        dispatch(setNewAssetPPSRFile(assetPPSRFile));
      };

      const handleNewActivityLog = (activityLog) => {
        dispatch(setNewActivityLog(activityLog));
      }

      const handleNewServiceUsageLog = (serviceUsageLog) => {
        dispatch(setNewServiceUsageLog(serviceUsageLog));
      }

      const handleConnect = () => {
        socket.emit("joinApplicationRoom", applicationId);
      };

      const handleReconnect = () => {
        socket.emit("joinApplicationRoom", applicationId);
      };

      socket.on("commercialCreditFileUploaded", handleNewCommercialCreditFile);
      socket.on("customerCreditFileUploaded", handleNewCustomerCreditFile);
      socket.on("intercomChatNotification", handleChatOptionEnable);
      socket.on("employmentVerificationUploaded", handleNewCustomerEmploymentVerificationFile);
      socket.on("bankStatementFileUploaded", handleNewBankStatementFile);
      socket.on("documentFileUploaded", handleNewDocumentFile);
      socket.on("assetPPSRFileUploaded", handleNewAssetPPSRFile);
      socket.on("newActivityLog", handleNewActivityLog);
      socket.on("newServiceUsageLog", handleNewServiceUsageLog);
      socket.on("connect", handleConnect);
      socket.on("reconnect", handleReconnect);

      return () => {
        // Cleanup functions
        socket.emit("leaveApplicationRoom", currentRoom);
        socket.off("commercialCreditFileUploaded", handleNewCommercialCreditFile);
        socket.off("customerCreditFileUploaded", handleNewCustomerCreditFile);
        socket.off("intercomChatNotification", handleChatOptionEnable);
        socket.off("employmentVerificationUploaded", handleNewCustomerEmploymentVerificationFile);
        socket.off("bankStatementFileUploaded", handleNewBankStatementFile);
        socket.off("documentFileUploaded", handleNewDocumentFile);
        socket.off("assetPPSRFileUploaded", handleNewAssetPPSRFile);
        socket.off("newActivityLog", handleNewActivityLog);
        socket.off("newServiceUsageLog", handleNewServiceUsageLog);
        socket.off("connect", handleConnect);
        socket.off("reconnect", handleReconnect);
      };
    }
  }, [applicationId, currentRoom, socket]);

  useEffect(() => {
    if (openActivity && applicationId) {
      dispatch(getApplicationActivityLog(applicationId));
    }
  }, [application?.customers, application?.asset, application?.entities, application?.loanDetails]);

  useEffect(() => {
    if (applicationId) {
      dispatch(getApplicationActivityLog(applicationId));
    }
  }, []);

  const handle = {
    cloneApplication: () => {
      dispatch(getCloneApplication(applicationId)).then(({payload}) => {
        payload?.status_code === STATUS_CODE.success && navigate(`/application/${payload?.data?._id}`);
      });
    },
    handleApplicationComplete: (isComplete) => {
      setIsApplicationComplete(isComplete);
    },
    handleApplicationSubmitSuccess: () => {

      setSubmitButtonAction({ color: "success", text: "Application submitted successfully" });

      setTimeout(() => {
        setSubmitButtonAction({ color: "primary", text: "Submit" });
      }, 6000)
    },
    handleApplicationDownload: async () => {
      await dispatch(
        getApplicationDownloadUrl({ applicationId: application._id })
      ).unwrap();
    },
    handleSubmitApplication: async () => {
      dispatch(saveValue({ loadingSubmitApplication: true }));
      dispatch(submitApplicationToEmail({ applicationId: application._id })).then((res) => {
        setSubmitSuccess(true);
        if (!res?.payload?.response_error) {
          enqueueSnackbar(res?.payload?.data?.status_message || SUCCESS_MESSAGE.applicationSubmitted, {
            variant: "success",
            autoHideDuration: 5000,
          });
        } else {
          enqueueSnackbar(res?.payload?.status_message || ERRORS_MESSAGE.fetchErrorMsg, {
            variant: "error",
            autoHideDuration: 5000,
          });
        }
      });
    },
    activityLog: () => {
      const extractKey = [
        "addresses",
        "customerAssets",
        "documents",
        "emails",
        "employers",
        "expenses",
        "income",
        "liabilities",
        "phoneNumbers",
      ];

      const list = _.flatMap(Object.keys(application), (item) => {
        if (["asset", "loanDetails"].includes(item)) {
          return [{ type: item, ...application[item] }];
        }

        if (["customers", "entities"].includes(item)) {
          return _.flatMap(application[item], (cusEnt) => {
            const result = [{ type: item, ...cusEnt }];
            _.forEach(cusEnt, (value, key) => {
              if (extractKey.includes(key) && value.createdAt) {
                result.push({ type: key, ...value });
              }
            });
            return result;
          });
        }
        return [];
      });

      return list;
    },
    applicationLoad: async () => {
      dispatch(saveValue({ gettingApplication: true, open: false }));
      if (applicationId) await dispatch(getApplication(applicationId)).unwrap();
    },
    applicationTitle: (applicationType) => {
      if (applicationType === APPLICATION_TYPES.COMMERCIAL) return "Commercial Asset Application";
      if (applicationType === APPLICATION_TYPES.CONSUMER) return "Consumer Asset Application";
      if (applicationType === APPLICATION_TYPES.PERSONAL) return "Unsecured Consumer Application";
    },
    addCustomer: () => {
      dispatch(
        createCustomer({
          applicationId: application._id,
          entities: application?.entities,
        }),
      );
    },
    showRequired: (currentRequiredFields) => {
      // console.log("currentRequiredFields", currentRequiredFields);
      const personalDetailError = {};
      currentRequiredFields?.customer?.forEach((field) => {
        const fieldValue = field.split("_")[0];
        // const filterField = _.startCase(fieldValue?.split(regex.startCaseRegex)[1]);
        const fieldEndVal = fieldValue?.split(regex.startCaseRegex)[1];

        // console.log("filterField", filterField);
        // console.log("fieldEndVal", fieldEndVal);
        // console.log("fieldValue", fieldValue);
        if (field.includes("customerAssets")) {
          const endField = field.split("customerAssets.[0].")[1] ?? field.split("customerAssets[0].")[1];
          personalDetailError[endField] = fieldRequiredMessage.customerAssetsErrMsg[fieldEndVal];
        } else if (field.includes("liabilities")) {
          const endField = field.split("liabilities.[0].")[1] ?? field.split("liabilities[0].")[1];
          if (endField.includes("description")) {
            personalDetailError[`liability_${endField}`] = fieldRequiredMessage.liabilityErrMsg[fieldEndVal];
          } else {
            personalDetailError[endField] = fieldRequiredMessage.liabilityErrMsg[fieldEndVal];
          }
        } else if (field.includes("income")) {
          const endField = field.split("income.[0].")[1] ?? field.split("income[0].")[1];
          personalDetailError[endField] = fieldRequiredMessage.incomeErrMsg[fieldEndVal];
        } else if (field.includes("expenses")) {
          const endField = field.split("expenses.[0].")[1] ?? field.split("expenses[0].")[1];
          personalDetailError[endField] = fieldRequiredMessage.expenseErrMsg[fieldEndVal];
        } else if (field.includes("employers")) {
          const endField = field.split("employers.[0].")[1];
          const endFieldKey =
            endField && endField !== undefined && endField.includes("address")
              ? endField.split("address.")[1]
              : endField;
          if (endFieldKey && endFieldKey.includes("custom") && !endFieldKey.includes("customerTitle")) {
            const endCustomFieldKey = endFieldKey.replace("_customError", "");
            personalDetailError[endCustomFieldKey] = fieldRequiredMessage.customErrMsg[fieldEndVal];
          } else {
            personalDetailError[endFieldKey] = fieldRequiredMessage.employeeErrMsg[fieldEndVal];
          }
        } else if (fieldEndVal === undefined) {
          if (field.includes("custom") && !field.includes("customerTitle")) {
            const customFieldKey = field.replace("_customError", "");
            personalDetailError[customFieldKey] = fieldRequiredMessage.customErrMsg[fieldValue];
          } else {
            personalDetailError[field] = fieldRequiredMessage[fieldValue];
          }
        }
        setGuarantorsError(personalDetailError);
      });
      dispatch(
        setRequiredFieldsErrors({
          ...requireFieldErrors,
          ...personalDetailError,
        }),
      );
      // dispatch(setValidationRequireFieldError({
      //   ...validationRequireFieldError,
      //   customerErrors: {
      //     ...validationRequireFieldError?.customerErrors,
      //     ...personalDetailError
      //   }
      // }))
      const assetsFieldError = {};
      currentRequiredFields?.asset?.forEach((field) => {
        assetsFieldError[field] = fieldRequiredMessage[field];
      });

      const agreementFieldError = {};
      currentRequiredFields?.agreement?.forEach((field) => {
        agreementFieldError[field] = fieldRequiredMessage[field];
      });

      const entityFieldError = {};
      currentRequiredFields?.entity?.forEach((field) => {
        const fieldValue = field.split("_")[0];
        entityFieldError[field] = fieldEntityRequiredMessage[fieldValue];
      });

      setEntityFieldErrors({
        ...entityFieldErrors,
        ...entityFieldError,
      });
      dispatch(
        setValidationRequireFieldError({
          ...validationRequireFieldError,
          agreementError: {
            ...validationRequireFieldError?.agreementError,
            ...agreementFieldError,
          },
          assetErrors: {
            ...validationRequireFieldError?.assetErrors,
            ...assetsFieldError,
          },
          entityFieldErrors: {
            ...validationRequireFieldError?.entityFieldErrors,
            ...entityFieldError,
          },
          customerErrors: {
            ...validationRequireFieldError?.customerErrors,
            ...personalDetailError,
          },
        }),
      );

      // currentRequiredFields?.customer?.forEach((fields) => {
      //   Object.keys(fields).map((field)=>{
      //     const filterField = _.startCase(field?.split(regex.startCaseRegex)[1]);
      //     const fieldEndVal = field?.split(regex.startCaseRegex)[1];

      //   })
      // })
    },
    
  };

  useEffect(() => {
    handle.applicationLoad();
    return function cleanUp() {
      dispatch(clearState());
    };
  }, [applicationId]);

  useEffect(() => {
    if (url) openInNewTab(url);
  }, [url]);

  const [skipConsumer, setSkipConsumer] = useState(true);
  const [skipPersonal, setSkipPersonal] = useState(true);
  const [skipCommercial, setSkipCommercial] = useState(true);

  const consumerResult = useGetConsumerLendersQuery({}, { skip: skipConsumer });
  const personalResult = useGetPersonalLendersQuery({}, { skip: skipPersonal });
  const commercialResult = useGetCommercialLendersQuery({}, { skip: skipCommercial });

  useEffect(() => {
    switch (application.applicationType) {
      case "consumer":
        setSkipConsumer(false);
        setSkipPersonal(true);
        setSkipCommercial(true);
        break;
      case "personal":
        setSkipConsumer(true);
        setSkipPersonal(false);
        setSkipCommercial(true);
        break;
      case "commercial":
        setSkipConsumer(true);
        setSkipPersonal(true);
        setSkipCommercial(false);
        break;
      default:
        setSkipConsumer(true);
        setSkipPersonal(true);
        setSkipCommercial(true);
        break;
    }
  }, [application.applicationType]);

  // Determine which result to use based on applicationType
  const result =
    application.applicationType === "consumer"
      ? consumerResult
      : application.applicationType === "personal"
        ? personalResult
        : application.applicationType === "commercial"
          ? commercialResult
          : { data: null, error: null, isLoading: false };

  const { data: lenderData, isFetching } = result;

  useEffect(() => {
    const request = window.indexedDB.open("financeableDB");
    let queries;
    request.onsuccess = async (event) => {
      const db = request.result;
      const objectStore = db.transaction(db.objectStoreNames[0], "readonly").objectStore("keyvaluepairs");
      const objectStoreData = objectStore.get("persist:root");

      objectStoreData.onsuccess = (e) => {
        if (objectStoreData.result) {
          const data = JSON.parse(objectStoreData.result ?? "");
          const lenderApiData = JSON.parse(data.lenderApi ?? "");
          queries = lenderApiData.queries;
          Object.keys(queries ?? {}).forEach(async (query) => {
            const queryTimestamp = Math.floor(queries[query].fulfilledTimeStamp / 1000) * 1000;
            // console.log((await logTimestamp()) > queryTimestamp);
            if (["pending", "rejected"].includes(queries[query].status) || (await logTimestamp()) > queryTimestamp) {
              switch (application.applicationType) {
                case "consumer":
                  dispatch(
                    lenderApi.endpoints.getConsumerLenders.initiate({}, { subscribe: true, forceRefetch: true }),
                  );
                  break;
                case "personal":
                  dispatch(
                    lenderApi.endpoints.getPersonalLenders.initiate({}, { subscribe: true, forceRefetch: true }),
                  );
                  break;
                case "commercial":
                  dispatch(
                    lenderApi.endpoints.getCommercialLenders.initiate({}, { subscribe: true, forceRefetch: true }),
                  );
                  break;
                default:
                  break;
              }
            }
          });
        }
      };
    };

    request.onerror = (e) => console.log("Error connecting to IndexDB");

    const logTimestamp = async () => {
      const { data } = await reusableFetch(`lender/log`, "GET", null);
      const lenderLog = data.data.lenderLog;
      const logTimestamp = Math.floor((new Date(lenderLog?.createdAt) || 0) / 1000) * 1000;
      return logTimestamp;
    };
  }, [application.applicationType]);

  // const {
  //   data: lenderData,
  //   // error,
  //   // isLoading,
  // } = useLenderQuery(
  //   {},
  //   {
  //     // pollingInterval: 5000,
  //     // skip: !!application.applicationType,
  //   },
  // );
  const applicationTitleString = handle.applicationTitle(application.applicationType);

  // const applicationTypeString =
  // application?.applicationType?.charAt(0).toUpperCase() + application?.applicationType?.slice(1);
  return (
    <React.Fragment>
      {gettingApplication ? (
        <Grid
          container
          style={{
            marginTop: "64px",
            padding: "30px 0px 100px",
            height: "calc(100vh - 100px)",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PageLoader text="Application loading..." />
        </Grid>
      ) : (
        <Grid container item xs={12}>
          <Grid
            container
            item
            xs={12}
            md={10}
            padding={{ xs: 0, sm: "20px 40px 20px 40px" }}
          >

            <Stack sx={{ flexDirection:"row",justifyContent:"space-between", width:"100%",mb:"30px",alignItems:"center"}}>
            <Typography
              variant="h3"
              style={{
                fontSize: "22px",
                marginRight:"10px"
              }}
              >
              {applicationTitleString} Application{" "}
              {application?.humanId && `- ${application?.humanId}`}
            </Typography>
            <Button
                size="small"
                variant="outlined"
                disabled={gettingClonedApplication}
                onClick={() => handle.cloneApplication()}
              >
                {gettingClonedApplication ? 'Cloning...' : 'Clone Application'}
              </Button>
              </Stack>
            {(application.applicationType === APPLICATION_TYPES.CONSUMER ||
              application.applicationType === APPLICATION_TYPES.PERSONAL) &&
              application?.customers?.length > 0 &&
              application?.customers?.map((customer, i) => (
                <CustomerDetails
                  key={application._id}
                  // numberOfCustomer={application?.customers?.length}
                  customer={customer}
                  fieldErrors={guarantorsError}
                  setFieldErrors={setGuarantorsError}
                  applicationType={application.applicationType}
                  applicationId={application._id}
                  index={i}
                />
              ))}

            {(application.applicationType === APPLICATION_TYPES.CONSUMER ||
              application.applicationType === APPLICATION_TYPES.PERSONAL) &&
              application?.customers?.length < 1 && (
                <Grid
                  container
                  item
                  xs={12}
                  style={{
                    margin: "0 0 30px",
                    padding: "0 0 20px",
                    borderBottom: "1px solid rgba(0,0,0,0.12)",
                  }}
                >
                  <Grid item xs={12} md={2}>
                    <Stack direction="row">
                      <PersonIcon style={{ marginRight: "10px" }} />
                      <Typography>Add borrower</Typography>
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={10}
                    style={{
                      padding: "0 0 0 10px",
                    }}
                  >
                    <LoadingButton
                      variant="outlined"
                      disabled={!isDisable}
                      onClick={() => {
                        handle.addCustomer();
                        setGuarantorFieldErrors({
                          customerTitle: "",
                          firstName: "",
                          lastName: "",
                          IDNumber: "",
                          dateOfBirth: "",
                          IDCardNumber: "",
                          phoneNumber: "",
                          emailAddress: "",
                          address: "",
                        });
                      }}
                    >
                      Add borrower
                    </LoadingButton>
                    {guarantorFieldErrors?.firstName !== "" &&
                      application?.customers?.length === 0 && (
                        <Typography
                          style={{
                            marginTop: "5px",
                            marginLeft: "14px",
                            color: "#d32f2f",
                            fontSize: "0.75rem",
                            fontWeight: "400",
                          }}
                        >
                          Please add borrower
                        </Typography>
                      )}
                  </Grid>
                </Grid>
              )}
 
            {/* Asset */}
            {(application?.applicationType === APPLICATION_TYPES.PERSONAL ||
              application?.applicationType === APPLICATION_TYPES.CONSUMER) && (
                <Asset
                  applicationType={application.applicationType}
                  fieldErrors={assetFieldErrors}
                  applicationId={application._id}
                  setFieldErrors={setAssetFieldErrors}
                />
              )}

            {(application.applicationType === APPLICATION_TYPES.CONSUMER ||
              application.applicationType === APPLICATION_TYPES.PERSONAL) &&
              application?.customers?.length > 0 && (
                <Grid
                  container
                  item
                  xs={12}
                  style={{
                    margin: "0 0 30px",
                    padding: "0 0 20px",
                    borderBottom: "1px solid rgba(0,0,0,0.12)",
                  }}
                >
                  <Grid item xs={12} md={2}>
                    <Stack direction="row">
                      <PersonIcon style={{ marginRight: "10px" }} />
                      <Typography>Add borrower</Typography>
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={10}
                    style={{
                      padding: "0 0 0 10px",
                    }}
                  >
                    <LoadingButton
                      color="secondary"
                      variant="contained"
                      disabled={!isDisable}
                      onClick={handle.addCustomer}
                    >
                      Add {convertToOrdinal(application?.customers?.length)}{" "}
                      borrower
                    </LoadingButton>
                  </Grid>
                </Grid>
              )}

            {application?.applicationType === APPLICATION_TYPES.COMMERCIAL ? (
              <React.Fragment>
                {application?.entities?.map((entity, i) => (
                  <Entity
                    application={application}
                    entitys={entity}
                    entities={application?.entities}
                    index={i}
                    fieldsErrors={entityFieldErrors}
                    setFieldsErrors={setEntityFieldErrors}
                  />
                ))}
                <AddedEntity fieldsErrors={entityFieldErrors} setFieldsErrors={setEntityFieldErrors} />
              </React.Fragment>
            ) : null}

            {application.applicationType === APPLICATION_TYPES.COMMERCIAL &&
              <Asset
                applicationType={application.applicationType}
                fieldErrors={assetFieldErrors}
                setFieldErrors={setAssetFieldErrors}
                applicationId={application._id}
              />
            }

            <LoanDetails applicationType={application.applicationType} />

            {/* {application?.applicationType === APPLICATION_TYPES.COMMERCIAL &&
              !application?.entities?.[0] && (
                <Entity
                  application={application}
                  fieldsErrors={entityFieldErrors}
                  setFieldsErrors={setEntityFieldErrors}
                />
              )} */}

            {application.applicationType === APPLICATION_TYPES.COMMERCIAL &&
              application?.customers?.length > 0 &&
              application?.customers?.map((customer, i) => (
                <CustomerDetails
                  key={application._id}
                  // numberOfCustomer={application?.customers?.length}
                  customer={customer}
                  fieldErrors={guarantorsError}
                  setFieldErrors={setGuarantorsError}
                  applicationType={application.applicationType}
                  applicationId={application._id}
                  index={i}
                />
              ))}

            {application.applicationType === APPLICATION_TYPES.COMMERCIAL && application?.customers?.length < 1 && (
              <Grid
                container
                item
                xs={12}
                style={{
                  margin: "0 0 30px",
                  padding: "0 0 20px",
                  borderBottom: "1px solid rgba(0,0,0,0.12)",
                }}
              >
                <Grid item xs={12} md={2}>
                  <Stack direction="row">
                    <PersonIcon style={{ marginRight: "10px" }} />
                    <Typography>
                      {application.applicationType === APPLICATION_TYPES.COMMERCIAL ? "Guarantor" : "Borrower"}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={10}
                  style={{
                    padding: "0 0 0 10px",
                  }}
                >
                  <LoadingButton
                    variant="outlined"
                    disabled={!isDisable}
                    onClick={() => {
                      handle.addCustomer();
                      setGuarantorFieldErrors({
                        customerTitle: "",
                        firstName: "",
                        lastName: "",
                        IDNumber: "",
                        dateOfBirth: "",
                        IDCardNumber: "",
                        phoneNumber: "",
                        emailAddress: "",
                        address: "",
                        yearsAt: "",
                        monthsAt: "",
                        IDExpiryDate: "",
                      });
                    }}
                  >
                    Add {application.applicationType === APPLICATION_TYPES.COMMERCIAL ? "Guarantor" : "Borrower"}
                  </LoadingButton>
                  {guarantorFieldErrors?.firstName !== "" && application?.customers?.length === 0 && (
                    <Typography
                      style={{
                        marginTop: "5px",
                        marginLeft: "14px",
                        color: "#d32f2f",
                        fontSize: "0.75rem",
                        fontWeight: "400",
                      }}
                    >
                      Please add{" "}
                      {application.applicationType === APPLICATION_TYPES.COMMERCIAL ? "guarantor" : "borrower"}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            )}

            {application.applicationType === APPLICATION_TYPES.COMMERCIAL && application?.customers?.length > 0 && (
              <Grid
                container
                item
                xs={12}
                style={{
                  margin: "0 0 30px",
                  padding: "0 0 20px",
                  borderBottom: "1px solid rgba(0,0,0,0.12)",
                }}
              >
                <Grid item xs={12} md={2}>
                  <Stack direction="row">
                    <PersonIcon style={{ marginRight: "10px" }} />
                    <Typography>
                      Add {application.applicationType === APPLICATION_TYPES.COMMERCIAL ? "Guarantor" : "Borrower"}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={10}
                  style={{
                    padding: "0 0 0 10px",
                  }}
                >
                  <LoadingButton
                    color="secondary"
                    variant="contained"
                    disabled={!isDisable}
                    onClick={handle.addCustomer}
                  >
                    Add {convertToOrdinal(application?.customers?.length)}{" "}
                    {application.applicationType === APPLICATION_TYPES.COMMERCIAL ? "Guarantor" : "Borrower"}
                  </LoadingButton>
                </Grid>
              </Grid>
            )}

            {(application.applicationType === APPLICATION_TYPES.CONSUMER ||
              application.applicationType === APPLICATION_TYPES.PERSONAL) && (
                <>
                  <IntroducerDeclaration
                    introducerDeclarationAgree={
                      application?.introducerDeclarationAgree
                    }
                    applicationId={application._id}
                    errorField={agreementsFieldErrors}
                    setErrorField={setAgreementsFieldErrors}
                  />
                  <NCCPDeclaration
                    whatIsThePurposeOfThisApplication={
                      application?.customers?.[0]?.customerDisclaimer?.whatIsThePurposeOfThisApplication
                    }
                    whyDoesThisLoanTermSuitYourRequirements={
                      application?.customers?.[0]?.customerDisclaimer?.whyDoesThisLoanTermSuitYourRequirements
                    }
                    whatAreYouLookingForInYourLoan={
                      application?.customers?.[0]?.customerDisclaimer?.whatAreYouLookingForInYourLoan
                    }
                    anyForeseeableChangesImpactingLoanRepayment={
                      application?.customers?.[0]?.customerDisclaimer?.anyForeseeableChangesImpactingLoanRepayment
                    }
                    howApplicantIntendToDoLoanRepaymentAfterChange={
                      application?.customers?.[0]?.customerDisclaimer?.howApplicantIntendToDoLoanRepaymentAfterChange
                    }
                    increaseInLoanAmountForFinancingCostOfInsurance={
                      application?.customers?.[0]?.customerDisclaimer?.increaseInLoanAmountForFinancingCostOfInsurance
                    }
                    balloonPaymentAtTheEndOfTheLoan={
                      application?.customers?.[0]?.customerDisclaimer?.balloonPaymentAtTheEndOfTheLoan
                    }
                    whatToDoWithBalloonPaymentAtTheEndOfTheLoanTerm={
                      application?.customers?.[0]?.customerDisclaimer?.whatToDoWithBalloonPaymentAtTheEndOfTheLoanTerm
                    }
                    application={application}
                    customerId={application?.customers?.[0]?._id}
                    errorField={nccpFieldErrors}
                    setErrorField={setNccpFieldErrors}
                  />
                </>
              )}


        <Supplier applicationId={application._id} />

            {/* Submit Application */}
            {permissions?.application?.internal_submit && (
              <Grid
                container
                item
                xs={12}
                style={{
                  margin: "0",
                  justifyContent: "end",
                  borderTop: "1px solid rgba(0,0,0,0.43)",
                  paddingTop: "30px"
                }} >
                <Stack spacing={3} direction="row" >
                  <LoadingButton
                    color="primary"
                    endIcon={<FileCopyOutlinedIcon />}
                    // loading={loadingDownloadUrl}
                    loadingPosition="end"
                    variant="outlined"
                    size="large"
                    disabled={!isApplicationComplete}
                    onClick={async () => {
                      if (!url) {
                        handle.handleApplicationDownload({ applicationId: application._id })
                      }
                    }}
                    href={url}
                  >
                    Download Application File
                  </LoadingButton>

                  <LoadingButton
                    // color="secondary"
                    color={submitButtonAction?.color || "secondary"}
                    endIcon={<SendOutlinedIcon />}
                    loading={loadingSubmitApplication}
                    loadingPosition="end"
                    variant="contained"
                    size="large"
                    disabled={!isApplicationComplete}
                    onClick={() => handle.handleSubmitApplication()}
                  >
                    {submitButtonAction?.text}
                  </LoadingButton>
                </Stack>
              </Grid>
            )}

          </Grid>
          {permissions?.lenders && hasTrueValue(permissions?.lenders) &&
            (<Grid container item display={{ xs: "none", md: "grid" }} sm={2} md={2} lg={2} padding={"20px"}>
              <Sidebar
                open={open}
                title={"Expand lenders"}
                onClickFn={() => {
                  dispatch(saveValue({ open: !open, openManualQuote: false }));
                  if (!open) {
                  dispatch(setValidationRequireFieldError({}));
                  dispatch(setRequiredFieldsErrors({}));
                }
              }}
              bottom={""}
              right={true}
              left={false}
              disabled={false}
              forwardIcon={<ArrowForwardIosIcon fontSize={"large"} />}
              backwardIcon={<ChecklistRtlIcon fontSize="large" />}
              component={
                (!isFetching && lenderData) ? (
                  <ProductSelector onShowRequired={handle.showRequired} lenderData={lenderData} />
                ) : (
                  <PageLoader text={"Lenders Loading"} />
                )}
              />
              <Sidebar
                open={openManualQuote}
                title={"Manual Quote"}
                onClickFn={() => dispatch(saveValue({ openManualQuote: !openManualQuote }))}
                bottom={""}
                right={true}
                left={false}
                disabled={false}
                forwardIcon={<CalculateOutlinedIcon fontSize={"large"} />}
                backwardIcon={<CalculateOutlinedIcon fontSize="large" />}
                component={<ManualQuote application={application} />}
              />
            </Grid>
            )}

          {permissions?.lenders && !hasTrueValue(permissions?.lenders) &&
            <Grid conatiner item sm={2} md={2} lg={2} style={{ padding: "20px" }}>
              <Box
                style={{
                  position: "fixed",
                  overflowY: "wrap",
                  overflowX: "wrap",
                  height: "calc(100vh - 140px)",
                  scrollbarColor: "white rebeccapurple",
                  scrollbarWidth: "thin",
                  paddingRight: "8px",
                  marginTop: "70px",
                }}
                sx={{
                  '&.MuiBox-root::-webkit-scrollbar': {
                    width: '7px', height: "7px"
                  },
                  '&.MuiBox-root::-webkit-scrollbar-track': {
                    background: '#f1f1f1',
                  },
                  '&.MuiBox-root::-webkit-scrollbar-thumb': {
                    backgroundColor: '#999',
                  },
                  '&.MuiBox-root::-webkit-scrollbar-thumb:hover': {
                    background: '#616161',
                  },
                }}
              >
                <LoanSummary />
              </Box>
            </Grid>
          }
 <Box display={{ xs: "none", md: "block" }}>
          <Box display={{ xs: "none", md: "block" }}>
            <Sidebar
              open={openNotes}
              title={"Notes"}
              onClickFn={() => setOpenNotes(!openNotes)}
              bottom={520}
              right={false}
              left={true}
              dispatch={false}
              icon={<EditNoteIcon fontSize="large" />}
            // component={<Notes />}
              component={<EditorNotes handleApplicationComplete={handle.handleApplicationComplete} />}
            />
            <Sidebar
              open={openComments}
              title={"Comments"}
              onClickFn={() => {
                setOpenComments(!openComments);
                if (!openComments === true && applicationId) {
                  dispatch(getApplicationComments(applicationId));
                }
              }}
              bottom={450}
              right={false}
              left={true}
              overflowY={"hidden"}
              dispatch={false}
              icon={<CommentOutlinedIcon fontSize="large" />}
              component={
                <Comments
                  comments={comments}
                  applicationId={applicationId}
                  gettingApplicationComment={gettingApplicationComment}
                />
              }
            />
            <Sidebar
              open={openActivity}
              title={"Activity log"}
              onClickFn={() => {
                setOpenActivity(!openActivity);
                if (!openActivity === true && applicationId) {
                  dispatch(getApplicationActivityLog(applicationId));
                }
              }}
              bottom={400}
              right={false}
              left={true}
              disabled={false}
              icon={<EventNoteOutlinedIcon fontSize="large" />}
              component={<ActivityLogSidebar activityList={activityLog} openActivity={openActivity} />}
            />
            <Sidebar
              open={openServiceLog}
              title={"Service log"}
              onClickFn={() => {
                setOpenServiceLog(!openServiceLog);
                if (!openServiceLog === true && applicationId) {
                  dispatch(getApplicationServiceLog(applicationId));
                }
              }}
              bottom={400}
              right={false}
              left={true}
              disabled={false}
              icon={<ViewHeadlineIcon fontSize="large" />}
              component={<ServiceLogSidebar serviceList={applicationServiceLog} loader={applicationServiceLogLoader} />}
            />
            <Sidebar
              open={openSMS}
              title={"SMS (coming soon)"}
              onClickFn={() => setOpenSMS(!openSMS)}
              bottom={370}
              right={false}
              left={true}
              disabled={true}
              icon={<ChatBubbleIcon fontSize="large" />}
              component={""}
            />
            <Sidebar
              open={openEmail}
              title={"Emails (coming soon)"}
              onClickFn={() => setOpenEmail(!openEmail)}
              bottom={200}
              right={false}
              left={true}
              disabled={true}
              icon={<EmailIcon fontSize="large" />}
              component={""}
            />
          </Box>
          </Box>

          {/* <Sidebar
            open={openActivity}
            title={"Activity log"}
            onClickFn={() => setOpenActivity(!openActivity)}
            bottom={55}
            right={false}
            left={true}
            disabled={false}
            component={<ActivityLogSidebar activityList={activityLog} />}
          /> */}

          <Snackbar
            open={submitSuccess}
            autoHideDuration={8000}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            onClose={() => setSubmitSuccess(false)}
          >
            <Alert onClose={() => setSubmitSuccess(false)} elevation={6} severity="success" sx={{ width: "100%" }}>
              Application submitted
            </Alert>
          </Snackbar>
          <Snackbar
            open={linkSent}
            autoHideDuration={8000}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            onClose={() => dispatch(saveValue({ linkSent: false }))}
          >
            <Alert
              onClose={() => dispatch(saveValue({ linkSent: false }))}
              elevation={6}
              severity="success"
              sx={{ width: "100%" }}
            >
              Link sent
            </Alert>
          </Snackbar>
          <Snackbar
            open={gotASICData}
            autoHideDuration={8000}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            onClose={() => dispatch(saveValue({ linkSent: false }))}
          >
            <Alert
              onClose={() => dispatch(saveValue({ linkSent: false }))}
              elevation={6}
              severity="success"
              sx={{ width: "100%" }}
            >
              ASIC Data retrieved
            </Alert>
          </Snackbar>
        </Grid>
      )}
    </React.Fragment>
  );
});

export default Application;
